import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import ADD_EMPLOYEE from '../../queries/createEmployeeByEmail';
import ADD_PROGRAM from '../../queries/addProgram';
import GET_LOCATIONS_BY_IDS from '../../queries/getLocationsByIds';
import GET_ALL_LOCATIONS from '../../queries/getAllLocations';
import { UPDATE_LOCATION } from '../../queries/updateLocation';
import { useUser } from '../../context/userContext';
import { Circles } from 'react-loader-spinner';

const AddDriver = () => {
  const [emailPersonal, setEmailPersonal] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [emailSent, setEmailSent] = useState(false);
  const [selectedLocationId, setSelectedLocationId] = useState('');
  const [isPersonalVehicle, setIsPersonalVehicle] = useState(false);
  
  const { userId, userLocations, userRole } = useUser();
  // const { data: locationData, loading: locationLoading } = useQuery(GET_LOCATIONS_BY_IDS, {
  //   variables: { ids: userLocations },
  // });
  // Conditionally use the query based on user role
  const { data: locationData, loading: locationLoading } = useQuery(
    userRole === 'Admin' ? GET_ALL_LOCATIONS : GET_LOCATIONS_BY_IDS,
    {
      variables: userRole === 'Admin' ? {} : { ids: userLocations },
    }
  );

  const [addEmployee] = useMutation(ADD_EMPLOYEE);
  const [addProgram] = useMutation(ADD_PROGRAM);
  const [updateLocation] = useMutation(UPDATE_LOCATION);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      // Step 1: Add Program
      const { data: programData } = await addProgram({
        variables: {
          input: {
            name: 'Mobile Driver Onboard',
            status: 'ACTIVE',
            operator: 'TRULIEVE',
          },
        },
      });
  
      if (programData) {
        const programId = programData.addProgram._id;
        try {
          // Step 2: Add Employee
          const { data: employeeData } = await addEmployee({
            variables: {
              input: {
                emailPersonal,
                emailCompany: emailPersonal,
                locationReference: selectedLocationId,
                firstName,
                lastName,
                program: [programId],
                startDate: new Date().toISOString(),
                position: 'DRIVER',
                employmentStatus: 'EMPLOYED',
                managerReference: userId,
                personalVehicle: isPersonalVehicle
              },
            },
          });
  
          if (employeeData) {
            const employeeId = employeeData.addEmployee._id; // Get the new employee's ID
            console.log('Employee added successfully:', employeeData.addEmployee);
            setEmailSent(true);
            console.log('selectedLocationId:', selectedLocationId);
            try {
              // Step 3: Update Location - Push new employeeId into the employee array
              const { data: locationData } = await updateLocation({
                variables: {
                  id: selectedLocationId, // Use the selected location ID
                  input: {
                    employees: {_id: employeeId}, // Push the new employee ID into the employees array
                  },
                },
              });
  
              console.log('Location updated successfully with new employee:', locationData.updateLocation);
            } catch (locationError) {
              console.error('Error updating location:', locationError);
            }
          }
        } catch (employeeError) {
          console.error('Error adding employee:', employeeError);
        }
      }
    } catch (programError) {
      console.error('Error adding program:', programError);
    }  
  };

  return emailSent ? (
    <div className="flex flex-col items-center p-4 sm:p-6">
      <h3 className="text-center text-2xl font-semibold text-gray-600">Employee Email Sent!</h3>
      <p className="text-center mt-4">The onboarding form has been sent successfully.</p>
    </div>
  ) : (
    <div className="min-h-full" style={{ overflowY: 'auto', overflowX: 'hidden'}}>
      <h2 className="text-2xl font-semibold text-gray-700 mb-6 text-center">Add Your Driver</h2>
      <form onSubmit={handleSubmit} className="space-y-6 h-full">
        <div>
          <label className="block text-sm font-medium text-gray-700">First Name</label>
          <input 
            type="text" 
            value={firstName} 
            onChange={(e) => setFirstName(e.target.value)} 
            className="w-full px-4 py-2 border border-gray-300 rounded-xlg shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Last Name</label>
          <input 
            type="text" 
            value={lastName} 
            onChange={(e) => setLastName(e.target.value)} 
            className="w-full px-4 py-2 border border-gray-300 rounded-xlg shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Personal Email</label>
          <input 
            type="email" 
            value={emailPersonal} 
            onChange={(e) => setEmailPersonal(e.target.value)} 
            className="w-full px-4 py-2 border border-gray-300 rounded-xlg shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Select Location</label>
          <select 
            value={selectedLocationId} 
            onChange={(e) => setSelectedLocationId(e.target.value)} 
            className="w-full px-4 py-2 border border-gray-300 rounded-xlg shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
          >
            <option value="">Select a location</option>
            {/* {locationData?.getLocationsByIds.map((location: any) => (
              <option key={location._id} value={location._id}>{location.name}</option>
            ))} */}
             {/* {locationData?.locations.map((location:any) => (
              <option key={location._id} value={location._id}>{location.name}</option>
            ))} */}
            {userRole === 'Admin'
            ? locationData?.getAllLocations.map((location: any) => (
                <option key={location._id} value={location._id}>{location.name}</option>
              ))
            : locationData?.getLocationsByIds.map((location: any) => (
                <option key={location._id} value={location._id}>{location.name}</option>
              ))}
          </select>
        </div>

        {/* Checkbox for Driving a Personal Vehicle */}
        <div className="flex items-center">
          <input
            type="checkbox"
            id="personalVehicle"
            checked={isPersonalVehicle}
            onChange={() => setIsPersonalVehicle(!isPersonalVehicle)}
            className="mr-3 w-5 h-5"
          />
          <label htmlFor="personalVehicle" className="text-lg font-med text-gray-900 pt-2">
            Will driver operate a personal vehicle?
          </label>
        </div>

        {/* Informational Text */}
        <p className="text-sm text-gray-500 mt-4">
          I’m ready to send this driver an onboarding form to collect their personal driving information.
          <br /><br />
          Once they complete this form, you’ll receive an email notification for you to review.
        </p>

        <div className="flex justify-end gap-3">
          <button type="submit" className="w-full py-2 bg-indigo-600 text-white rounded-lg">SEND</button>
        </div>
        {locationLoading && <Circles height="80" width="80" color="#007e9d" ariaLabel="loading-spinner" />}
      </form>
    </div>
  );
};

export default AddDriver;
