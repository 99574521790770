import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { LOGOUT_MUTATION } from '../../queries/logoutMutation';

type NavBarItem = {
    href: string;
    icon: any;
    label: string;
};

type LeftNavBarProps = {
    items: NavBarItem[];
    className?: string;
};

const LeftNavBar = ({ items, className = '' }: LeftNavBarProps) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [logoutMutation] = useMutation(LOGOUT_MUTATION);

    const isCurrent = (path: string) => location.pathname === path;

    const handleSignOut = async () => {
        try {
            await logoutMutation();
            navigate('/', { replace: true });
        } catch (error) {
            console.error('Error logging out:', error);
        }
    };

    return (
        <div className={`fixed inset-y-0 z-50 flex w-60 flex-col ${className}`}>
            {/* Main sidebar container */}
            <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-700 px-6 pb-4 shadow-md">
                {/* Logo area */}
                <div className="flex h-16 shrink-0 items-center">
                    {/* Add your logo here if needed */}
                </div>

                {/* Navigation */}
                <nav className="flex flex-1 flex-col">
                    <ul role="list" className="flex flex-1 flex-col gap-y-2">
                        {items.map((item) => (
                            <li key={item.label}>
                                <a
                                    href={item.href}
                                    className={`
                                        group flex items-center gap-x-3 rounded-md p-2 text-sm font-medium leading-6
                                        transition duration-200
                                        ${isCurrent(item.href) 
                                            ? 'bg-gray-800 text-white' 
                                            : 'text-gray-400 hover:text-white hover:bg-gray-800'
                                        }
                                    `}
                                >
                                    <img 
                                        src={item.icon} 
                                        className={`
                                            h-6 w-6 shrink-0 transition duration-200
                                            ${isCurrent(item.href)
                                                ? 'opacity-100'
                                                : 'opacity-75 group-hover:opacity-100'
                                            }
                                        `} 
                                        alt={item.label}
                                    />
                                    {item.label}
                                </a>
                            </li>
                        ))}
                    </ul>

                    {/* Logout button */}
                    <button
                        onClick={handleSignOut}
                        className="
                            mt-2 rounded-md p-2 px-4 text-sm font-medium
                            text-orange-300 hover:text-white
                            hover:bg-gray-800
                            focus:outline-none focus:ring-2 focus:ring-orange-400 focus:ring-offset-2 focus:ring-offset-gray-700
                            transition duration-200
                        "
                    >
                        Logout
                    </button>
                </nav>
            </div>
        </div>
    );
};

export default LeftNavBar;