import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useMutation } from '@apollo/client';

import BadgeFlatWithDot from '../badgeFlatWithDot';
import SlideOverNav from '../slideOverNav';
import RequiredInfoComponent from '../requiredInfoDash';
import DocumentDisplay from '../documentDisplayRightSlide';
import { UPDATE_EMPLOYEE } from '../../queries/updateEmployee';
import { UPDATE_PROGRAM } from '../../queries/updateProgram';

// import '../rightSlideOverFeed1/rightpopover.css'

interface NavItem {
  label: string;
  viewId: string;
}

interface MobileApprovalProps {
  title: string;
  emailCompany: string;
  phone: string;
  name: string;
  employeeId: number;
  isActive: boolean;
  navItems: NavItem[];
  programArray: any[];
  onClose: () => void;
  onShowNotification: () => void;
}

const MobileApproval = ({ 
  title, 
  emailCompany, 
  phone, 
  name, 
  employeeId, 
  isActive, 
  navItems,
  programArray,
  onClose,
  onShowNotification
}: MobileApprovalProps) => {
  const [mobileApprovalData, setMobileApprovalData] = useState<any[]>([]);
  const [isApproved, setIsApproved] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeView, setActiveView] = useState(navItems[activeIndex].viewId);

  const [updateEmployee] = useMutation(UPDATE_EMPLOYEE);
  const [updateProgram] = useMutation(UPDATE_PROGRAM);

  const navigate = useNavigate();
  const badgeStatus = isActive ? 'fit' : 'unfit';

  const handleUpdate = async () => {
    if (!isApproved) {
      try {
        let mutationVariables = {
          id: employeeId,
          input: {
            ffdStatus: 'PENDING',
            mobileApproval: {
              approved: true,
              timestamp: new Date().toISOString(),
              approvedData: activeView,
            },
          },
        };
      // Check if it's the last item in the navItems
        if (activeIndex >= navItems.length - 1) {
          mutationVariables.input.ffdStatus = 'ACTIVE';
        // Find the program with name "Mobile Driver Onboard" and set its status to INACTIVE

          const mobileDriverOnboardProgram = programArray.find(
            (program) => program.name === 'Mobile Driver Onboard'
          );

          if (mobileDriverOnboardProgram) {
            await updateProgram({
              variables: {
                id: mobileDriverOnboardProgram._id,
                input: {
                  status: 'INACTIVE',
                },
              },
            });
          }
        }

        await updateEmployee({
          variables: mutationVariables,
        });

        if (activeIndex >= navItems.length - 1) {
          onShowNotification();
        } else {
          navigateToNext();
        }
      } catch (err) {
        console.error('Error updating Employee:', err);
      }
    } else {
      navigateToNext();
    }
  };

  const handleNameClick = () => {
    navigate(`/employeeDetail/${employeeId}`);
  };

  const navigateToNext = () => {
    if (activeIndex < navItems.length - 1) {
      const nextIndex = activeIndex + 1;
      setActiveIndex(nextIndex);
      setActiveView(navItems[nextIndex].viewId);
    }
  };

  const handleMobileApprovalData = (approvalData: any[]) => {
    setMobileApprovalData(approvalData);
  };

  useEffect(() => {
    const isAlreadyApproved = mobileApprovalData.some(approval => approval.approvedData === activeView);
    setIsApproved(isAlreadyApproved);
  }, [activeView, mobileApprovalData]);

  return (
    <>
      <div className="bg-white sm:rounded-lg">
        <div className="px-4 py-2">
          <div>
            <dl>
              <div className="bg-white px-4 py-2 sm:grid sm:gap-4 sm:px-6">
                <h1 className="mt-1 text-2xl text-gray-900 mb-0 sm:col-span-2 flex items-center" onClick={handleNameClick}>
                  <span className="underline-location">{name}</span>
                  <div className="status mt-0 text-center text-sm font-medium">
                    <span className={`status-indicator ${isActive ? 'status-active' : 'status-inactive'} custom-margin-left`}>
                      <BadgeFlatWithDot
                        title={<span>{isActive ? 'Ready for Review' : 'NOT COMPLETED'}</span>}
                        status={badgeStatus}
                      />
                    </span>
                  </div>
                </h1>
              </div>
              <div className="bg-white px-2 py-1 sm:grid sm:grid-cols-3 sm:gap-2 sm:px-6">
                <p className="text-sm text-gray-500 mb-0 sm:mt-0 sm:col-span-2">{phone}</p>
              </div>
              <div className="bg-white px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-2 sm:px-6">
                <h3 className="text-sm text-gray-500 mb-0 sm:mt-0 sm:col-span-2">{emailCompany}</h3>
              </div>
            </dl>
          </div>
        </div>
        <SlideOverNav
          activeView={activeView}
          setActiveView={setActiveView}
          navItems={navItems}
          clickable={false}
        />
        <div className="content-area">
          {activeView === 'requiredInfo' && (
            <RequiredInfoComponent employeeid={employeeId} onMobileApprovalFetched={handleMobileApprovalData} />
          )}
          {activeView === 'driver' && (
            <DocumentDisplay employeeid={employeeId} type='driver' />
          )}
          {activeView === 'history' && (
            <DocumentDisplay employeeid={employeeId} type='insurance' />
          )}
        </div>
      </div>
      <div className="sticky bottom-0 bg-white p-4">
        <hr className="border-t border-gray-400 mt-4 mb-4" />
        <div className="action-buttons flex justify-end gap-3">
        <button onClick={handleUpdate} className="update-btn text-white py-1 px-4 bg-blue-600 rounded hover:bg-blue-700">
            {isApproved ? "Already Approved" : "Approve?"}
          </button>
        </div>
      </div>
    </>
  );
};

export default MobileApproval;