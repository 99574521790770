import { gql } from '@apollo/client';

const ADD_EMPLOYEE = gql`
    mutation AddEmployee($input: EmployeeInput) {
        addEmployee(input: $input) {
            _id
            emailPersonal
            # include the personal email address in both fields for now
            emailCompany
            # this should be stored in sessions for admin or manager when login happens 
            #capture from session, then pass to employee as a string for display in the table
            location
            firstName
            lastName
            program{
                _id
            }
            locationReference {
                # id should be stored in sessions on user login
                _id
            }
            personalVehicle
        }
    }
`;
export default ADD_EMPLOYEE;
export { ADD_EMPLOYEE };