import { Fragment } from 'react';
import { useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import EmailForm from '../slideOverBodies/emailForm'; 
import UploadDocumentForm from '../slideOverBodies/uploadDocument';
import GenerateNewOtp from '../slideOverBodies/generateNewOtp';
import AddDriver from '../slideOverBodies/addDriverForm';
import MobileApproval from '../slideOverBodies/mobileApproval';
import UpcomingDocumentExpiration from '../slideOverBodies/upcomingDocumentExpiration';
import add_file from '../../assets/storySetIllustrations/Add files-rafiki.png';
import sent_message from '../../assets/storySetIllustrations/Sent Message-pana.png';
import driver from '../../assets/storySetIllustrations/City driver-rafiki (1).png';
import Notification from '../notification';
import { set } from 'date-fns';

interface BaseSlideOverProps {
  closePopover: () => void;
  title: string;
  isOpen: boolean;
  email?: string | undefined;
  id?: string | undefined;
  name?: string | undefined;
  emailCompany?: string | undefined;
  phone?: string | undefined;
  employeeId?: number | undefined;
  isActive?: boolean | undefined;
  navItems?: any[] | undefined;
  programArray?: any[] | undefined;
  documentType?: string | undefined;
  expirationDate?: string | undefined;
  documentImageUrl?: string | undefined;
  documentNumber?: string | undefined;
}

const BaseSlideOver = ({ closePopover, title, isOpen, email, id, name, emailCompany, phone, employeeId, isActive, navItems, programArray, documentType, expirationDate, documentImageUrl, documentNumber}: BaseSlideOverProps) => {
  const [showNotification, setShowNotification] = useState(false);
  
  const handleClose = () => {
    closePopover(); // Call the close function passed in as a prop
  };

  const handleShowNotification = () => {
    setShowNotification(true);
    setTimeout(() => {
      setShowNotification(false);
      closePopover();
      window.location.reload();
    }, 3000);
  }
  
  const renderBodyContent = () => {
    if (title === "SEND EMAIL") {
      return <EmailForm employeeEmail={email}/>;
    }
    else if (title === "UPLOAD DOCUMENT") {
        return <UploadDocumentForm />
    }
    else if(title==="ONBOARD A NEW DRIVER") {
        return <AddDriver />
    }
    else if(title==="GENERATE NEW OTP") {
      return <GenerateNewOtp employeeEmail={email} id={id} name={name} />
    }
    else if(title==="Mobile Driver Onboard") {
      return <MobileApproval 
        title={title} 
        emailCompany={emailCompany ||''} 
        phone={phone ||''} 
        name={name || ''} 
        employeeId={employeeId || 0} 
        isActive={isActive || false} 
        navItems={navItems || []} 
        programArray={programArray || []} 
        onClose={closePopover} 
        onShowNotification={handleShowNotification} />
    }
    else if(title==="UPCOMING DOCUMENT EXPIRATION") {
      return <UpcomingDocumentExpiration 
        name={name || ''} 
        documentType={ documentType ||''} 
        expirationDate={ expirationDate || ''}
        documentImageUrl = {documentImageUrl} 
        documentNumber={documentNumber}
        onEmailDriver={function (): void {
          throw new Error('Function not implemented.');
        } } 
        onUpdateDocument={function (): void {
          throw new Error('Function not implemented.');
        } }        
      />
    }
    else {
      return <div>Other content based on the title</div>;
    }
  };

  return (
    <Transition.Root show={isOpen} as={Fragment} >
      <Dialog as="div" className="relative z-10" onClose={handleClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-hidden">
          {showNotification && <Notification />}
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto relative w-screen max-w-2xl h-full" style={{overflow:'auto'}}>
                  <div className="flex flex-col bg-white shadow-xl h-full">
                    <div className="px-4 py-4 sm:px-6 bg-gray-700">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-lg font-medium text-gray-100 uppercase">
                          {title}
                        </Dialog.Title>
                        <div className="ml-3 h-7 flex items-center">
                          <button
                            type="button"
                            className="rounded-md text-gray-100 hover:text-gray-400 focus:outline-none"
                            onClick={handleClose}
                          >
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    {/* Image Section */}
                    {title === "SEND EMAIL" && (
                      <div className="flex justify-center mt-6">
                        <img src={sent_message} alt="Illustration" className="w-60 h-60" style={{}}/>
                      </div>
                    )}
                    {title === "UPLOAD DOCUMENT" && (
                        <div className="flex justify-center mt-6">  
                            <img src={add_file} alt="Illustration" className="w-60 h-60" style={{}}/>
                        </div>
                    )}
                    {title === "ONBOARD A NEW DRIVER" && (
                        <div className="flex justify-center mt-6">
                            <img src={driver} alt="Illustration" className="w-60 h-60" style={{}}/>
                        </div>
                    )}
                    {/* Body Section */}
                    <div className="px-4 py-6">
                        {renderBodyContent()}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default BaseSlideOver;
