import React from 'react';
import { getNavItemClasses } from '../../styles/utils';

export type NavBarItem = {
    label: string;
    href: string;
    onClick?: (event: React.MouseEvent) => void;
    isActive?: boolean;
};

type NavBarProps = {
    items: NavBarItem[];
    className?: string;
};

const HorizontalNavBar = ({ items, className = '' }: NavBarProps) => {
    return (
        <nav className={`bg-gradient-to-r from-gradient-start to-gradient-end rounded-lg ${className}`}>
            <ul className="flex items-center justify-start h-9 px-2">
                {items.map((item, index) => (
                    <li key={index} className="mr-1 last:mr-0">
                        <a
                            href={item.href}
                            onClick={item.onClick}
                            className={`
                                px-4 py-2 rounded-md
                                text-white hover:text-white/90
                                hover:bg-white/10
                                transition-colors duration-200
                                ${item.isActive ? 'bg-white/20' : ''}
                            `}
                        >
                            {item.label}
                        </a>
                    </li>
                ))}
            </ul>
        </nav>
    );
};

export default HorizontalNavBar;