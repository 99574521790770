import React from 'react';
import MainTemplate from '../components/mainTemplate';
import type { NavBarItem } from '../components/horizontalNavBar';
import '../css/auto.css';
import ListTemplate from '../components/listTemplate';
import { gql, useQuery } from '@apollo/client';
import {ReactComponent as VehiclesIcon} from '../assets/itw_car_icon.svg';
import { GET_ALL_VEHICLES } from '../queries/getAllVehicles';

//this uses the gql template literal tag to parse the query string into a query document

//NOTE:: leasedOrOwned field points to the lease status of the field: active or otherwise

const VehiclePage = () => {

  const pageTitle = "Vehicles"

  const menuItems ={
   menuItemsOne:[
      // {label:'AHello', href:'/'},
      // { label: 'Ocean', href: '#eagleRay' },
      // { label: 'Flag Flying in Southern India', href: '#bindi' }
    ], 

   menuItemsTwo:[
        // {label:'Dispensary', href:'/'},
        // { label: 'Retail', href: '#retail' },
        // { label: 'Pineapple Cakeface muthafakas', href: '#pineapple' }
    ]
  };

  const dropDownData = [
    {
        title: "USAGE TYPE ",
        menuItems: menuItems.menuItemsOne
    },
    {
        title: "LOCATION",
        menuItems: menuItems.menuItemsTwo
    }, 
    {
        title: "TITLED STATE",
        menuItems: menuItems.menuItemsTwo
    }
];
  
  const columnDefs= [
      { field: 'Location', flex: 1 },
      { field: 'Make', flex: 1 },
      { field: 'Model', flex: 1 }, 
      { field: 'License Plate Number', flex: 1 },
      { field: 'Vehicle Usage Status', flex: 1 },
      { field: 'Titled State', flex: 1 },
      { field: 'VIN', flex: 1 },
      { field: 'Leased or Owned', flex: 1 }  
  ];

  interface Vehicle {
    location: string;
    make: string;
    model: string;
    licensePlateNumber: string;
    vehicleUsageStatus: string;
    registereDState: string;
    vin: string;
    leasedOrOwned: string;
  }

    const { loading, error, data } = useQuery(GET_ALL_VEHICLES);
    if (error) {
      console.log('error',error);
      return <p>Error: {error.message}</p>;
    }

    const rowDataOne = data?.getAllVehicles.map((vehicle:Vehicle) => ({
    'Location': vehicle.location,
    'Make': vehicle.make,
    'Model': vehicle.model, 
    'License Plate Number': vehicle.licensePlateNumber,
    'Vehicle Usage Status': vehicle.vehicleUsageStatus,
    'Titled State': vehicle.registereDState,
    'VIN': vehicle.vin,
    'Leased or Owned': vehicle.leasedOrOwned
    })) || []

  return (
    <div className = "ma-continer">
      <MainTemplate />
      <ListTemplate 
        rowDataOne={rowDataOne} 
        pageTitle={pageTitle} 
        columnDefs={columnDefs} 
        dropDownData={dropDownData}
        mainIcon={<VehiclesIcon />}
      />
        {loading ? (
            //sketleton screens here?
            <p>Loading...</p> 
        ) : error ? (
            <p>Error: {error}</p>
        ) : (
            <p>list</p>
        )}
    </div>
  );
};

export default VehiclePage;