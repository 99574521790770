import { gql } from "@apollo/client";

const UPDATE_EMPLOYEE = gql`
  mutation UpdateEmployee($id: ID!, $input: EmployeeInput!) {
    updateEmployee(id: $id, input: $input) {
      _id
      personalVehicle
    }
  }
`;

export { UPDATE_EMPLOYEE };