import { gql } from '@apollo/client';

const GET_ALL_VEHICLES = gql`
  query GetAllVehicles {
    getAllVehicles {
      location
      make
      model
      licensePlateNumber
      vehicleUsageStatus
      registereDState
      vin
      leasedOrOwned
    }
  }
`;

export default GET_ALL_VEHICLES;
export { GET_ALL_VEHICLES };