import { useState } from 'react';
import { EnvelopeIcon, PencilSquareIcon } from '@heroicons/react/24/outline';

interface UpcomingDocExpirationProps {
  name: string;
  documentType: string;
  expirationDate: string;
  documentImageUrl?: string;
  documentNumber?: string;
  onEmailDriver: () => void;
  onUpdateDocument: () => void;
}

const UpcomingDocumentExpiration = ({
  name,
  documentType,
  expirationDate,
  documentImageUrl,
  documentNumber,
  onEmailDriver,
  onUpdateDocument
}: UpcomingDocExpirationProps) => {

  // Calculate days until expiration for conditional styling
  const daysUntilExpiration = () => {
    const today = new Date();
    const expDate = new Date(expirationDate);
    const diffTime = expDate.getTime() - today.getTime();
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };

  const getExpirationDisplay = () => {
    const days = daysUntilExpiration();
    if (days < 0) {
      return `Expired ${Math.abs(days)} days ago`;
    }
    return `Expires in ${days} days`;
  };

  const getExpirationColor = () => {
    const days = daysUntilExpiration();
    if (days < 0) return 'text-red-600';
    if (days < 30) return 'text-amber-600';
    return 'text-green-600';
  };

  return (
    <div className="flex flex-col h-full bg-white">
      {/* Driver Info Section */}
      <div className="bg-gray-50 px-4 py-5 sm:px-6 rounded-t-lg">
        <h3 className="text-xl font-semibold text-gray-900">{name}</h3>
        <p className="mt-1 text-sm text-gray-500">
          Document expiring soon: {documentType}
        </p>
      </div>

      {/* Document Details */}
      <div className="flex-1 px-4 py-5 sm:px-6">
        <div className="bg-white rounded-lg border border-gray-200 p-4">
          <dl className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-2">
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Document Type</dt>
              <dd className="mt-1 text-sm text-gray-900">{documentType}</dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Status</dt>
              <dd className={`mt-1 text-sm font-medium ${getExpirationColor()}`}>
                {getExpirationDisplay()}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Expiration Date</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {new Date(expirationDate).toLocaleDateString()}
              </dd>
            </div>
            {documentNumber && (
              <div className="sm:col-span-2">
                <dt className="text-sm font-medium text-gray-500">Document Number</dt>
                <dd className="mt-1 text-sm text-gray-900">{documentNumber}</dd>
              </div>
            )}
          </dl>
        </div>

        {/* Document Image */}
        {documentImageUrl && (
          <div className="mt-6">
            <label className="block text-sm font-medium text-gray-700">Document Image</label>
            <div className="mt-2 border rounded-lg overflow-hidden">
              <img
                src={documentImageUrl}
                alt={`${documentType} Document`}
                className="max-w-full h-auto object-contain"
              />
            </div>
          </div>
        )}
      </div>

      {/* Action Buttons */}
      <div className="border-t border-gray-200 bg-gray-50 rounded-b-lg">
        <div className="px-4 py-5 sm:px-6">
          <h4 className="text-sm font-medium text-gray-500 mb-4">Available Actions</h4>
          <div className="flex space-x-4">
            <button
              onClick={onEmailDriver}
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors"
            >
              <EnvelopeIcon className="h-5 w-5 mr-2" />
              Email Driver
            </button>
            <button
              onClick={onUpdateDocument}
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 transition-colors"
            >
              <PencilSquareIcon className="h-5 w-5 mr-2" />
              Update Document
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpcomingDocumentExpiration;