import { useNavigate, useLocation } from 'react-router-dom';
import './driverProfile.css'; 
import { useUploadContext } from '../../../context/uploadProvider';
import Header from '../progressDots/index';
import NameValidator from '../../../helpers/validateName';
import AddressValidator from '../../../helpers/addressValitator';
import CityValidator from '../../../helpers/cityValidator';
import ZipValidator from '../../../helpers/zipValidator';
import PhoneValidator from '../../../helpers/phoneValidator';
import { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { useUser } from '../../../context/userContext';

import { UPDATE_EMPLOYEE } from '../../../queries/updateEmployee';

function Profile(): JSX.Element {
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [firstNameErrors, setFirstNameErrors] = useState<string[]>([]);
  const [lastNameErrors, setLastNameErrors] = useState<string[]>([]);
  const [address, setAddress] = useState<string>('');
  const [addressErrors, setAddressErrors] = useState<string[]>([]);
  const [city, setCity] = useState<string>('');
  const [cityErrors, setCityErrors] = useState<string[]>([]);
  const [zip, setZip] = useState<string>('');
  const [zipErrors, setZipErrors] = useState<string[]>([]);
  const [phone, setPhone] = useState<string>('');
  const [phoneErrors, setPhoneErrors] = useState<string[]>([]);
  const [state, setState] = useState<string>('');
  const [stateErrors, setStateErrors] = useState<string[]>([]);
  const [gender, setGender] = useState<string>('select');
  const [genderErrors, setGenderErrors] = useState<string[]>([]);
  const [maritalStatus, setMaritalStatus] = useState<string>('select');
  const [maritalStatusErrors, setMaritalStatusErrors] = useState<string[]>([]);
  const [emergencyContact, setEmergencyContact] = useState<string>('');
  const [relationship, setRelationship] = useState<string>('');
  const [emergencyContactErrors, setEmergencyContactErrors] = useState<string[]>([]);
  const [relationshipErrors, setRelationshipErrors] = useState<string[]>([]);

  const navigate = useNavigate();
  const location = useLocation();
  const { extractedData } = useUploadContext();
  const { userId } = useUser();

  const [updateEmployee] = useMutation(UPDATE_EMPLOYEE);

  useEffect(() => {
    if (extractedData) {
      setFirstName(extractedData.firstName || '');
      setLastName(extractedData.lastName || '');
      setAddress(extractedData.address || '');
      setCity(extractedData.cityInAddress || '');
      setZip(extractedData.zipCodeInAddress || '');
      setState(extractedData.stateInAddress || '');
    }
  }, [extractedData]);

  const handleContinue = async (event: React.FormEvent) => {
    const firstNameValidation = NameValidator(firstName);
    const lastNameValidation = NameValidator(lastName);
    const addressValidation = AddressValidator(address);
    const cityValidation = CityValidator(city);
    const zipValidation =ZipValidator(zip)
    const phoneValidation = PhoneValidator(phone);

    const emergencyContactValidation = emergencyContact.trim() ? [] : ["Emergency Contact is required."];
    const relationshipValidation = relationship.trim() ? [] : ["Relationship is required."];

     // State, Gender, and Marital Status Validation
     const stateValidation = state ? [] : ["State selection is required."];
     const genderValidation = gender !== 'select' ? [] : ["Gender selection is required."];
     const maritalStatusValidation = maritalStatus !== 'select' ? [] : ["Marital Status selection is required."];

    if (!firstNameValidation.isValid || 
      !lastNameValidation.isValid || 
      !addressValidation.isValid || 
      !cityValidation.isValid || 
      !zipValidation.isValid ||
      !phoneValidation.isValid||
      emergencyContactValidation.length > 0 ||
      relationshipValidation.length > 0||
      stateValidation.length > 0 ||
      genderValidation.length > 0 ||
      maritalStatusValidation.length > 0) {
      setFirstNameErrors(firstNameValidation.errors);
      setLastNameErrors(lastNameValidation.errors);
      setAddressErrors(addressValidation.errors);
      setCityErrors(cityValidation.errors);
      setZipErrors(zipValidation.errors);
      setPhoneErrors(phoneValidation.errors);
      setEmergencyContactErrors(emergencyContactValidation);
      setRelationshipErrors(relationshipValidation);
      setStateErrors(stateValidation);
      setGenderErrors(genderValidation);
      setMaritalStatusErrors(maritalStatusValidation);
    } else {
      setFirstNameErrors([]);
      setLastNameErrors([]);
      setAddressErrors([]);
      setCityErrors([]);
      setZipErrors([]); 
      setPhoneErrors([]);
      setEmergencyContactErrors([]);
      setRelationshipErrors([]);
      setStateErrors([]);
      setGenderErrors([]);
      setMaritalStatusErrors([]);
      navigate('/mobileDriverOnboard/personal-vehicle');
    }
    try {
      const { data } = await updateEmployee({
        variables: {
          //userId is the employeeId in this page
          //add in here:
            //locManagerName 
            //dlExpiration -- add this on capture
            //location 
            //emailCompany -- add this on driverAdd 
            //driversLicenseNum -- add this on capture
            //program -- add this on driverAdd
          id: userId,
          input: {
            firstName,
            lastName,  
            addressStreet:address,
            addressCity:city,
            addressZip:zip,
            addressState:state,
            gender, 
            maritalStatus,
            emergencyContactName:emergencyContact,
            relationshipToEmployee:relationship,
            emergencyContactPhone:phone,
            
            // documentReference: [
            //   {
            //     documentType: 'Driver\'s License Front', // You can change this based on context
            //     documentId: documentId // Assuming documentId is already set from the previous capture
            //   },
            //   {
            //     documentType: 'Driver\'s License Back',
            //     documentId: documentIdDLBack // If you have this documentId for the insurance card
            //   }
            // ]
          }
        }
      });

      const personalVehicle = data?.updateEmployee?.personalVehicle;

      if (personalVehicle) {
        navigate('/mobileDriverOnboard/personal-vehicle-info');
      } else {
        navigate('/mobileDriverOnboard/confirmation'); // Replace with the actual route for the confirmation page
      }
    } catch (error) {
      console.error('Error updating employee:', error);
    }
      // navigate('/mobileDriverOnboard/personal-vehicle'); 
  }

  const handleBack = () => {
    navigate(-1);
  };

  return (
  <div className="account-setup mobile-main">
    <Header />
    <div className='mobile-body'>
      <div className="profile-form-section">
        <h2 className="profile-h2">Your Info</h2>
        <form>
          <div className="row">
            <div className="input-group half-width">
              <input 
                type="text" 
                id="firstName" 
                name="firstName" 
                placeholder="First Name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
              {firstNameErrors && <p style={{ color: 'red', fontSize: '11px' }}>{firstNameErrors}</p>}
            </div>
            <div className="input-group half-width">
              <input 
                type="text" 
                id="lastName" 
                name="lastName" 
                placeholder="Last Name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
              {lastNameErrors && <p style={{ color: 'red', fontSize: '11px'  }}>{lastNameErrors}</p>}
            </div>
          </div>
          <div className="row">
            <div className="input-group mid-width">
              <input 
                type="text" 
                id="streetAddress" 
                name="streetAddress" 
                placeholder="Street Address"
                value={address}
                onChange={(e)=>setAddress(e.target.value)}
              />
              {addressErrors && <p style={{ color: 'red', fontSize: '11px'  }}>{addressErrors}</p>}
            </div>
            <div className="input-group mid-width">
              <input 
                type="text" 
                id="city" 
                name="city" 
                placeholder="City"
                value={city}
                onChange={(e)=>setCity(e.target.value)}
              />
              {cityErrors && <p style={{ color: 'red', fontSize: '11px'  }}>{cityErrors}</p>}
            </div>
            <div className="input-group small-width">
              <select id="state" name="state" value={state} onChange ={(e)=> setState(e.target.value)}>
                <option value="">STATE</option>
                <option value="AL">AL</option>
                <option value="AK">AK</option>
                <option value="AZ">AZ</option>
                <option value="AR">AR</option>
                <option value="CA">CA</option>
                <option value="CO">CO</option>
                <option value="CT">CT</option>
                <option value="DE">DE</option>
                <option value="FL">FL</option>
                <option value="GA">GA</option>
                <option value="HI">HI</option>
                <option value="ID">ID</option>
                <option value="IL">IL</option>
                <option value="IN">IN</option>
                <option value="IA">IA</option>
                <option value="KS">KS</option>
                <option value="KY">KY</option>
                <option value="LA">LA</option>
                <option value="ME">ME</option>
                <option value="MD">MD</option>
                <option value="MA">MA</option>
                <option value="MI">MI</option>
                <option value="MN">MN</option>
                <option value="MS">MS</option>
                <option value="MO">MO</option>
                <option value="MT">MT</option>
                <option value="NE">NE</option>
                <option value="NV">NV</option>
                <option value="NH">NH</option>
                <option value="NJ">NJ</option>
                <option value="NM">NM</option>
                <option value="NY">NY</option>
                <option value="NC">NC</option>
                <option value="ND">ND</option>
                <option value="OH">OH</option>
                <option value="OK">OK</option>
                <option value="OR">OR</option>
                <option value="PA">PA</option>
                <option value="RI">RI</option>
                <option value="SC">SC</option>
                <option value="SD">SD</option>
                <option value="TN">TN</option>
                <option value="TX">TX</option>
                <option value="UT">UT</option>
                <option value="VT">VT</option>
                <option value="VA">VA</option>
                <option value="WA">WA</option>
                <option value="WV">WV</option>
                <option value="WI">WI</option>
                <option value="WY">WY</option>
              </select>
              {stateErrors && <p style={{ color: 'red', fontSize: '11px'  }}>{stateErrors}</p>}
            </div>
            <div className="input-group small-width">
              <input 
                type="tel" 
                id="zip" 
                name="zip" 
                placeholder="ZIP"
                value={zip}
                onChange={(e)=>setZip(e.target.value)}
              />
              {zipErrors && <p style={{ color: 'red', fontSize: '11px'  }}>{zipErrors}</p>}
            </div>
          </div>
          <div className="row">
            <div className="input-group half-width">
              <select id="gender" name="gender" value={gender} onChange={(e) => setGender(e.target.value)}>
                <option value="select">SELECT GENDER</option>
                <option value="FEMALE">FEMALE</option>
                <option value="MALE">MALE</option>
                <option value="other">NON BINARY</option>
              </select>
              {genderErrors && <p style={{ color: 'red', fontSize: '11px'  }}>{genderErrors}</p>}
            </div>
            <div className="input-group half-width">
              <select id="maritalStatus" name="maritalStatus" value={maritalStatus} onChange={(e) => setMaritalStatus(e.target.value)}>
                <option value="select">MARITAL STATUS</option>
                <option value="SINGLE">SINGLE</option>
                <option value="MARRIED">MARRIED</option>
              </select>
              {maritalStatusErrors && <p style={{ color: 'red', fontSize: '11px'  }}>{maritalStatusErrors}</p>}
            </div>
          </div>
        </form>
      </div>
      <div className="form-section secondary">
        <h2 className="profile-h2">Emergency Contact</h2>
        <form>
          <div className="row">
            <div className="input-group half-width">
              <input 
                type="text" 
                id="emergencyContact" 
                name="emergencyContact" 
                placeholder="Emergency Contact"
                value={emergencyContact}
                onChange={(e) => setEmergencyContact(e.target.value)}
              />
              {emergencyContactErrors.length > 0 && <p style={{ color: 'red', fontSize: '11px'  }}>{emergencyContactErrors[0]}</p>}
            </div>
            <div className="input-group small-width">
              <input 
                type="text" 
                id="relationship" 
                name="relationship" 
                placeholder="Relationship"
                value={relationship}
                onChange={(e) => setRelationship(e.target.value)}
              />
              {relationshipErrors.length > 0 && <p style={{ color: 'red', fontSize: '11px'  }}>{relationshipErrors[0]}</p>}
            </div>
            <div className="input-group small-width">
              <input 
                type="tel" 
                id="phoneNumber" 
                name="phoneNumber" 
                placeholder="Phone Number"
                value={phone}
                onChange={(e)=>setPhone(e.target.value)}
              />
              {phoneErrors && <p style={{ color: 'red', fontSize: '11px'  }}>{phoneErrors}</p>}
            </div>
          </div>
        </form>
      </div>
        <div className="profile-button-container">
          <button className="profile-continue-button" onClick={handleContinue}>CONTINUE</button>
          <button className="profile-back-button" onClick={handleBack}>BACK</button>
        </div>  
      </div>
    </div>
  );
}

export default Profile;
